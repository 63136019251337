import { gql } from "@apollo/client";

/**
 * Register customer mutation query.
 */
const PASSWORD_RESET = gql`
    mutation PasswordReset( $input: ResetUserPasswordInput! ) {
        resetUserPassword( input:$input ) {
            clientMutationId
            user {
              email
              username
            }
        }
    }
`;

export default PASSWORD_RESET;
