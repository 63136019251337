import React, { useState, useRef } from "react";
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { getOgImage, sanitize } from "../../utils/functions";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form"
import PASSWORD_RESET from "../../mutations/passwordReset";
import Spin from "../../components/spin/Spin";
import "./style.scss";
import { WPViewContent } from "../../utils/WPpixel";


const ResetPasswordPage = (props) => {
  const {
    pageContext: {
      title, seo, uri,
      acfResetPassword
    },
  } = props;
  
  useState(()=>{
    WPViewContent('View');
  },[]) 

  const {
    textInButton,
    textLabelPassword,
    textLabelRepeatPassword,
    textPasswordPolicy,
    errorPassword,
    errorFieldRequired,
    errorPasswordDontMatch,
    textBeforeForm,
    textAfterForm,
    successMessage
  } = acfResetPassword

  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");

  const onSubmit = (values, e) => {
    if (loading) return;
    setLoading(true);
    setErrorMessage('');
    setPassword(values.password);
    recoveryPasswordAction();
  }

  const [
    recoveryPasswordAction,
    { loading: recoveryPasswordLoading, error: registerError },
  ] = useMutation(PASSWORD_RESET, {
    variables: {
      input: {
        password: password
      },
    },

    onCompleted: (data) => {
      // If error.
      if (!isEmpty(registerError)) {
        setErrorMessage(registerError.graphQLErrors[0].message);
      }
      else {
        setSuccess(true)
      }
      setLoading(false);

    },

    onError: (error) => {
      if (error) {
        if (!isEmpty(error)) {
          setLoading(false);
          setErrorMessage(error.graphQLErrors[0].message);
        }
      }
    },

  });


  return (
    <Layout lang={lang} page="resetPasswordPage" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={title}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"resetPasswordPage"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <div className="container container--small">
              <div className="resetPasswordPage page">
                <h2 className="resetPasswordPage__heading heading--big heading--dash">{title}</h2>
                {textBeforeForm &&
                  <div className="content resetPasswordPage__before">
                    <div dangerouslySetInnerHTML={{ __html: textBeforeForm }} />
                  </div>
                }
                <form className="form resetPasswordPage__wrapper" onSubmit={handleSubmit(onSubmit)}>

                  <div className="form__input">
                    <label className="form__label" htmlFor="password">
                      {textLabelPassword}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="password"
                      type="password"
                      ref={passwordRef}
                      {...register("password", {
                        required: true,
                        pattern: {
                          value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.{8,}).*$/i,
                          message: errorPassword
                        }
                      })}
                    />
                    <span className="form__label__info">{textPasswordPolicy}</span>
                    <div className="form__error"><span>{errors.password ? (errors.password.message ? (errors.password.message) : errorFieldRequired) : ''}</span></div>
                  </div>

                  <div className="form__input">
                    <label className="form__label" htmlFor="repeatPassword">
                      {textLabelRepeatPassword}
                      <span className="form__label__required">*</span>
                    </label>
                    <input
                      id="repeatPassword"
                      type="password"
                      {...register("repeatPassword", {
                        required: true,
                        validate: value =>
                          value === passwordRef.current || errorPasswordDontMatch
                      })}
                    />
                    <div className="form__error"><span>{errors.repeatPassword ? (errors.repeatPassword.message ? (errors.repeatPassword.message) : errorFieldRequired) : ''}</span></div>
                  </div>


                  {!isEmpty(errorMessage) && (
                    <div
                      className="alert alert-danger"
                      dangerouslySetInnerHTML={{ __html: sanitize(errorMessage) }}
                    />
                  )}

                  <div className="resetPasswordPage__button form__button">
                    <button type="submit">{textInButton}</button>
                    {loading ? <Spin /> : ""}
                  </div>

                  {success && <div className="form__thx">{successMessage}</div>}

                </form>

                {textAfterForm &&
                  <div className="content resetPasswordPage__after">
                    <div dangerouslySetInnerHTML={{ __html: textAfterForm }} />
                  </div>
                }
              </div>
            </div>
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  )
};
export default ResetPasswordPage;

